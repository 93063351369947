import { Producer } from 'types/producer'
import { Product } from 'types/product-data'
import { CmsFaqArticle } from 'types/cms/single-types'

export type BoltGoProps = {
  producers: Producer[]
  questions: CmsFaqArticle[]
}

export type SectionProps = {
  isDark?: boolean
  startRegistration: (product: Product) => void
}

export enum SessionStorageKeys {
  FROM = 'fromPage',
  PRODUCT = 'chosenProduct'
}
